define(['app', '$console', '$window', 'accessibleModalHelper', 'accessibilityFocusHelper'], (app, $console, $window, accessibleModalHelper, accessibilityFocusHelper) => {

  const foundationFinderMultiBrandUploadPhoto = () => {
    const comp = {
      maxImageSize: 1750.0
    };

    const _select = {
      input: '.foundationFinderMultiBrand_takePhotoInput',
      imagePreview: '.foundationFinderMultiBrand_photoModalPhoto > img',
      captchaResponse: '#g-recaptcha-response',
      reCaptcha: '#g-recaptcha-ff',
      modal: '.foundationFinderMultiBrand_photoModal',
      modalExit: '.foundationFinderMultiBrand_photoModalExit',
      modalError: '.foundationFinderMultiBrand_photoModalError',
      retakeButton: '[data-ff-retake-photo]',
      retryButton: '[data-ff-retry-photo]',
      readBasics: '[data-ff-read-basics-flow]',
      confirmButton: '[data-ff-send-photo]',
      bestShadeTitle: '.foundationFinderMultiBrand_resultsText_BEST',
      sentPhotoText: '.foundationFinderMultiBrand_photoModalSentPhotoText',
      step: '.stepIndicator',
      steps: '.stepIndicator_step',
      previousButton: '.foundationFinderMultiBrand_previous_button',
      quiz: '[data-component=quiz]',
    };

    const _attrib = {
      hidden: 'data-hidden',
      state: 'data-photo-modal-state',
      errorState: 'data-error-state',
    };

    const _class = {
      noScroll: 'foundationFinderMultiBrand_noScroll',
      stepActive: 'stepIndicator_step-active',
      stepDone: 'stepIndicator_step-done',
      hide: 'hide'
    };

    const _channels = {
      uploadPhoto: 'foundationFinder/uploadPhoto',
      showPhotoModal: 'foundationFinder/showPhotoModal',
      saveSelectedAnswers: 'foundationFinderMultiBrandUploader/saveSelectedAnswers',
      showResults: 'foundationFinder/results',
      goForward: 'foundationFinder/next',
      accessibleModalHelper: 'modal/reloadAccessibleModalHelper',
      captchaExpired: 'foundationFinder/captchaExpired',
      captchaChecked: 'foundationFinder/captchaChecked'
    };

    const selectedAnswers = {};

    comp.init = (el) => {
      comp.element = el;
      comp.reader = new FileReader();
      comp.inputs = [...el.querySelectorAll(_select.input)];
      comp.imagePreview = el.querySelector(_select.imagePreview);
      comp.captchaResponse = el.querySelector(_select.captchaResponse);
      comp.modal = el.querySelector(_select.modal);
      comp.modalExit = el.querySelector(_select.modalExit);
      comp.modalError = el.querySelector(_select.modalError);
      comp.sentPhotoText = el.querySelector(_select.sentPhotoText);
      comp.retakeButton = el.querySelector(_select.retakeButton);
      comp.confirmButton = el.querySelector(_select.confirmButton);
      comp.previousButton = el.querySelector(_select.previousButton);
      comp.quiz = document.querySelector(_select.quiz);


      comp.inputs.map(element => element.addEventListener('click', comp.retakePhoto));
      comp.reader.addEventListener('load', comp.onLoad);
      comp.modalExit.addEventListener('click', comp.closeModal);
      comp.confirmButton.addEventListener('click', comp.confirmPhoto);
      comp.retakeButton.addEventListener('click', comp.retakePhoto);
      comp.previousButton.addEventListener('click', comp.goBackToQuiz);
      if (comp.element.querySelector(_select.reCaptcha)) {
        comp.captchaExpired();
      }

      comp.subscribe();
      comp.stepIndicatorSetUp();
      return comp;
    };

    comp.captchaAccessibilityInit = () => {
      comp.captchaResponse.setAttribute('aria-hidden', 'true');
      comp.captchaResponse.setAttribute('tabindex', '-1');
    }

    comp.captchaChecked = () => {
      comp.confirmButton.disabled = false
      comp.captchaAccessibilityInit()
    }

    comp.captchaExpired = () => {
      comp.confirmButton.disabled = true
      grecaptcha.reset();
      comp.captchaAccessibilityInit()
    }

    comp.stepIndicatorSetUp = () => {
      const stepIndicator = comp.element.querySelector(_select.step);
      const steps = [...stepIndicator.querySelectorAll(_select.steps)];

      for (let [index, step] of steps.entries()) {
        if (index < steps.length - 1) {
          step.classList.add(_class.stepDone);
        } else {
          step.classList.add(_class.stepActive);
        }
      }
    };

    comp.goBackToQuiz = () => {
      comp.element.classList.add(_class.hide);
      comp.quiz.classList.remove(_class.hide);

      const stepIndicators = comp.quiz.querySelectorAll(_select.step);
      stepIndicators.length > 0 && accessibilityFocusHelper.focusAndScroll(stepIndicators[stepIndicators.length - 1]);
    };

    comp.subscribe = () => {
      app.subscribe(_channels.uploadPhoto, comp.changePreferenceFromChange);
      app.subscribe(_channels.showPhotoModal, comp.showModal);
      app.subscribe(_channels.saveSelectedAnswers, comp.saveSelectedAnswers);
      app.subscribe(_channels.captchaChecked, comp.captchaChecked);
      app.subscribe(_channels.captchaExpired, comp.captchaExpired);
    };

    comp.saveSelectedAnswers = (selectedAnswers) => {
      comp.selectedAnswers = selectedAnswers;
    };

    comp.onInput = (event) => {
      const file = event.currentTarget.files[0];

      if (!event.currentTarget.files[0]) {
        $console.error('expected a file');
        return;
      }

      comp.imagePreview.src = '';

      comp.reader.readAsArrayBuffer(file);
      comp.showModal();
    };

    comp.onLoad = async () => {
      try {
        comp.imagePreview.src = await comp.fixPhoto(comp.reader.result);
        $window.setImmediate ? $window.setImmediate(comp.fixBottomBar) : $window.setTimeout(comp.fixBottomBar, 0);
      } catch (e) {
        comp.modal.setAttribute(_attrib.state, 'imageerror');

        comp.retryButton = comp.element.querySelector(_select.retryButton);
        comp.retryButton && comp.retryButton.addEventListener('click', comp.retakePhoto);

        comp.readBasics = comp.element.querySelector(_select.readBasics);
        comp.readBasics && comp.readBasics.addEventListener('click', comp.closeModal);
      }
    };

    comp.getOrientation = (dataUrl) => {
      var view = new DataView(dataUrl);

      if (view.getUint16(0, false) !== 0xFFD8) {
        return -2;
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        if (view.getUint16(offset+2, false) <= 8) {
          return -1;
        }

        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            return -1;
          }

          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);

          const tags = view.getUint16(offset, little);
          offset += 2;

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              return view.getUint16(offset + (i * 12) + 8, little);
            }
          }
        } else if ((marker & 0xFF00) !== 0xFF00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }

      return -1;
    };

    comp.arrayBufferToBase64 = (buffer) => {
      const bytes = new Uint8Array(buffer);
      let binary = '';

      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      return window.btoa(binary);
    };

    comp.isTransformationNeeded = () => {
      const img = document.createElement('img');
      img.style.display = 'none';
      document.body.appendChild(img);
      const imageObjCSS = $window.getComputedStyle(img, null);
      const imageOrientation = imageObjCSS.imageOrientation;
      document.body.removeChild(img);
      return imageOrientation !== 'from-image';
    };

    comp.fixPhoto = (dataArray) => new Promise((resolve, reject) => {
      const dataUrl = `data:image/png;base64,${comp.arrayBufferToBase64(dataArray)}`;
      const img = new Image();

      img.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxLength = Math.max(img.width, img.height);
        const scale = maxLength > comp.maxImageSize ? comp.maxImageSize / maxLength : 1;

        const width = img.width * scale;
        const height = img.height * scale;

        canvas.width = width;
        canvas.height = height;

        if (comp.isTransformationNeeded()) {
          const orientation = comp.getOrientation(dataArray);

          // set proper canvas dimensions before transform & export
          if (4 < orientation && orientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          // transform context before drawing image
          switch (orientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
          }
        }

        // draw image
        ctx.drawImage(img, 0, 0, width, height);

        // export base64
        resolve(canvas.toDataURL('image/jpeg', 0.9));
      };

      img.onerror = function() {
        reject();
      };

      img.src = dataUrl;
    });

    comp.dataURLtoFile = (dataurl) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], `image.${mime}`, {type:mime});
    };

    comp.confirmPhoto = () => {
      comp.modal.setAttribute(_attrib.state, 'sentPhoto');
      comp.sendPhoto();

      comp.accessibleModalHelper = new accessibleModalHelper(comp.modal, comp.closeModal, comp.sentPhotoText);
      comp.reloadSubscription = app.subscribe(_channels.accessibleModalHelper,
        target => comp.accessibleModalHelper.reload(comp.modal, target),
      );
    };

    // by this point they have sent the photo
    comp.changePreferenceFromChange = () => {
      comp.showModal();
      comp.modal.setAttribute(_attrib.state, 'sentPhoto');
      comp.sendPhoto();
    };

    comp.getQuizAnswers = () => {
      return comp.selectedAnswers;
    };

    comp.sendPhoto = async () => {
      let recaptcha;
      try {
        recaptcha = grecaptcha.getResponse();
      } catch (e) {
        $console.info('could not retrieve captcha response');
      }
      const file = comp.dataURLtoFile(comp.imagePreview.src);
      const body = new FormData();
      const quiz = comp.getQuizAnswers();

      body.append('image', file);
      body.append('quiz', JSON.stringify(quiz));
      if(recaptcha) {
        body.append('g-recaptcha-response', recaptcha);
      }

      const res = await $window.fetch('/matchesCard.foundationFinder', {
        method: 'post',
        body
      });
      //each recaptcha token is single-use
      if (comp.element.querySelector(_select.reCaptcha)) {
        comp.captchaExpired();
      }

      if (!res.ok) {
        return comp.onError(res);
      }

      app.publish(_channels.showResults, await res.text(), "", true);
      app.publish(_channels.goForward);

      comp.closeModal();

    };

    comp.onError = (res) => {
      comp.modal.setAttribute(_attrib.state, 'error');

      res.text().then(data => {
        let parent = document.querySelector(_select.modalError);
        parent.innerHTML = data;

        comp.retryButton = parent.querySelector(_select.retryButton);
        comp.retryButton && comp.retryButton.addEventListener('click', comp.retakePhoto);

        comp.readBasics = parent.querySelector(_select.readBasics);
        comp.readBasics && comp.readBasics.addEventListener('click', comp.closeModal);

        const modalHeading = comp.modal.querySelector('h2');
        comp.accessibleModalHelper = new accessibleModalHelper(comp.modal, comp.closeModal, modalHeading);
        comp.reloadSubscription = app.subscribe(_channels.accessibleModalHelper,
          target => comp.accessibleModalHelper.reload(comp.modal, target),
        );
      });

    };

    comp.retakePhoto = () => {
      comp.input = $window.document.createElement('input');
      comp.input.type = 'file';
      comp.input.accept = 'image/*';

      comp.input.addEventListener('change', comp.onInput);
      comp.input.dispatchEvent(new MouseEvent('click'));
    };

    comp.showModal = () => {
      comp.modal.setAttribute(_attrib.hidden, 'false');
      comp.modal.setAttribute(_attrib.state, 'default');

      $window.document.body.classList.add(_class.noScroll);
      $window.addEventListener('scroll', comp.fixBottomBar);

      const modalHeading = comp.modal.querySelector('h2');
      comp.accessibleModalHelper = new accessibleModalHelper(comp.modal, comp.closeModal, modalHeading);
      comp.reloadSubscription = app.subscribe(_channels.accessibleModalHelper,
        target => comp.accessibleModalHelper.reload(comp.modal, target),
      );
      app.publish('tracking/record', "Foundation Finder Yes", "Free shadows photo")
    };

    comp.closeModal = () => {
      comp.modal.setAttribute(_attrib.hidden, 'true');

      $window.document.body.classList.remove(_class.noScroll);
      $window.removeEventListener('scroll', comp.fixBottomBar);

      if (comp.closeSubscription) {
        comp.closeSubscription.remove();
        comp.closeSubscription = null;
      }

      if (comp.reloadSubscription) {
        comp.reloadSubscription.remove();
        comp.reloadSubscription = null;
      }

      comp.accessibleModalHelper && comp.accessibleModalHelper.close();
      accessibilityFocusHelper.focus(document.querySelector(_select.bestShadeTitle));
    };

    comp.fixBottomBar = () => {
      const rect = comp.modal.getBoundingClientRect();
      const bottom = Math.floor(rect.bottom);

      if (bottom > $window.innerHeight) {
        comp.modal.style.bottom = bottom - $window.innerHeight;
      } else {
        comp.modal.style.bottom = '';
      }
    };

    comp.selectedAnswers = selectedAnswers;
    comp._attrib = _attrib;
    comp._class = _class;

    return comp;
  };

  return foundationFinderMultiBrandUploadPhoto;
});


